import Face from "./face.svg"
import './App.css';

function App() {
    const link = 'https://afisha.yandex.ru/moscow/standup/bolshoi-standup-v-kinoteatre?source=place'

    return (
    <div className="App">
        <header className="App-header">
            <img src={Face} className="App-logo" alt="logo"/>
            <h1>Поздравляю с Новым годом, моя любимая Викуля!</h1>
            {/*<button  onClick={getGift}></button>*/}
            <a className="action" href={link} download>Получить подарок</a>
        </header>
    </div>
    );
}

export default App;
